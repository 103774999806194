import logo from "./logo.svg";
import "./App.css";
import {
  Box,
  Grid,
  Container,
  Button,
  Skeleton,
  InputLabel,
  FormControl,
  FilledInput,
  InputAdornment,
  Card,
  Typography,
  CardContent,
  Divider,
  IconButton,
  Link,
  Paper,
  CircularProgress,
} from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { Twitter } from "@mui/icons-material";
import { Telegram } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { http, createConfig } from "wagmi";
import { base } from "wagmi/chains";
import { ABI } from "./utils/ABI";
import {
  WagmiProvider,
  Connector,
  useConnect,
  useAccount,
  useDisconnect,
  useBalance,
  useWriteContract,
  useReadContract,
} from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { injected } from "wagmi/connectors";
import { formatEther, parseEther, parseUnits, isAddress } from "viem";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

const queryClient = new QueryClient();

const projectId = "fb57ffd9d69d94f849b13537ada27121";

const metadata = {
  name: 'Base Bots',
  description: 'Base Bots - Miners on Base',
  url: 'https://basebots.com', // origin must match your domain & subdomain
  icons: ['https://files.catbox.moe/4v6qje.png']
}

const config = defaultWagmiConfig({
  chains: [base],
  projectId,
  metadata,
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
});


const CONTRACT_ADDRESS = "0x1f1cC70Af6497cD75e1768d2d84126da5fc5caDC";
const BASE_TOKEN = "ETH";
const CG_TICKER = "ethereum";
const APP_URL = "https://basebots.farm/";

function App() {
  const [referrer, setReferrer] = useState(null);

  const { connectors, connect } = useConnect();

  const { disconnect } = useDisconnect();

  const { address } = useAccount();

  const userBalance = useBalance({ address });

  const contractBalance = useBalance({ address: CONTRACT_ADDRESS });

  const { writeContract } = useWriteContract({
    onError: (e) => toast.error(e)
  });

  const miners = useReadContract({
    abi: ABI,
    address: CONTRACT_ADDRESS,
    functionName: "getUserMiners",
    args: [address],
  });

  const eggs = useReadContract({
    abi: ABI,
    address: CONTRACT_ADDRESS,
    functionName: "getBotsSinceLastEvolution",
    args: [address],
  });

  const eggsToMain = useReadContract({
    abi: ABI,
    address: CONTRACT_ADDRESS,
    functionName: "calculateBotSell",
    args: [eggs.data ? eggs.data : "0"],
  });

  const [coinPrice, setCoinPrice] = useState(0);

  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const _ref = params.get("ref");
    console.log(_ref, isAddress(_ref));
    if (_ref && isAddress(_ref)) {
      setReferrer(_ref);
    }
  }, []);


  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch(
          `https://api.coingecko.com/api/v3/simple/price?ids=${CG_TICKER}&vs_currencies=usd`
        );
        const json = await response.json();
        setCoinPrice(json[CG_TICKER].usd);
      } catch (err) {
        console.error(err);
      }
    };
    fetchPrice();
  }, []);


  const handleCompound = () => {
    writeContract({
      abi: ABI,
      address: CONTRACT_ADDRESS,
      functionName: "evolveBots",
      args: [referrer || address],
    });
  };

  const handleSell = () => {
    writeContract({
      abi: ABI,
      address: CONTRACT_ADDRESS,
      functionName: "terminateBots",
      args: [],
    });
  };

  const handleDeposit = (e) => {
    e.preventDefault();
    writeContract({
      abi: ABI,
      address: CONTRACT_ADDRESS,
      functionName: "generateBots",
      args: [referrer || address],
      value: parseEther(userInput),
    });
  };

  function handleDecimalsOnValue(value) {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,10})/s;
    return value.match(regex)[0];
  }

  function truncate(str, maxDecimalDigits) {
    if (str.includes('.')) {
        const parts = str.split('.');
        return parts[0] + '.' + parts[1].slice(0, maxDecimalDigits);
    }
    return str;
}



  return (
    <div className="App">
      <Container maxWidth="sm">
        <Grid container justifyContent={"center"} pt={4}>
          <Grid item>
            <Box py={2} px={4} sx={{backgroundColor: "#07bc0c", borderRadius: "500px", color: "#fff", fontWeight: "bold", fontSize: 12, fontFamily: "IBM Plex Mono, monospace", lineHeight: "1.8"}}>
              
            🤖 Base Bots is PWA compatible 🤖<br/> Install the app on your phone by visting us on mobile 
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box mt={"80px"} mb={"120px"}>
        <Container style={{ backgroundColor: "#434345", color: "#ffffff", fontFamily: "IBM Plex Mono, monospace" }} maxWidth="sm">
          <Box>
            <Grid pt={2} container spacing={2} justifyContent={"center"}>
              <Grid item justifyContent={"center"}>
                {/* <Button fullWidth variant="outlined" onClick={handleConnect}>
                  {address || "Connect Wallet"}
                </Button> */}
                <w3m-button/>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2} my={4}>
            <Grid item xs={12}>
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item>
                  <img src="./robot_anim.gif" style={{height: 64}} alt="Base Bot Robot Spinning GIF"/>
                </Grid>
                <Grid item ml={4}>
                  <span style={{fontSize: 36, userSelect: "none"}}>
                  Base Bots
                  </span>
               
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4} mb={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: "bold",
                  fontFamily: "IBM Plex Mono",
                  color: "#84a4bc",
                }}
              >
                Asimov's Three Laws of Robotics
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent={"center"} mb={4}>
            <Grid item xs={8} fontWeight={"bold"} mb={1}>
            🤖 First Law
            </Grid>
            <Grid item xs={8}>
              A bot operator may buy bots using ETH
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent={"center"} mb={4}>
            <Grid item xs={8} fontWeight={"bold"} mb={1}>
            🤖 Second Law
            </Grid>
            <Grid item xs={8}>
              A bot operator may compound their rewards into more bots
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent={"center"} mb={4}>
            <Grid item xs={8} fontWeight={"bold"} mb={1}>
            🤖 Third Law
            </Grid>
            <Grid item xs={8}>
              A bot operator may withdraw their ETH rewards into their wallet
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent={"center"} my={6}>
            <Grid
              item
              xs={10}
              style={{ fontStyle: "italic" }}
              fontSize={12}
              lineHeight={1.8}
            >
              Enter the arena, the essence of maximizing an operator's potential hinges on the swarm of base bots at your disposal and the frequency of their compounding. Amass a legion of bots and weave the threads of their synergy with relentless mining. The more bots you accumulate and the more often you reinvest their capabilities, the greater the potential for earning more rewards in the vast landscape of algorithms and automation!
            </Grid>
          </Grid>
          <Grid container spacing={0} justifyContent={"center"} my={4}>
            <Grid item xs={12} md={8} fontSize={20} p={2}>
              <Grid
                container
                justifyContent={"space-between"}
                py={1}
                borderBottom={"solid"}
                borderColor={"#99b0f3"}
              >
                <Grid item>TVL</Grid>
                <Grid item fontWeight={"bold"}>
                  ${" "}
                  {contractBalance.data
                    ? (
                        parseFloat(contractBalance.data.formatted) * coinPrice
                      ).toFixed(0)
                    : "-"}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} fontSize={20} p={2}>
              <Grid
                container
                justifyContent={"space-between"}
                py={1}
                borderBottom={"solid"}
                borderColor={"#99b0f3"}
              >
                <Grid item>Contract Balance</Grid>
                <Grid item fontWeight={"bold"}>
                  {contractBalance.data
                    ? parseFloat(contractBalance.data.formatted).toFixed(2)
                    : "-"}{" "}
                  {BASE_TOKEN}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} fontSize={20} p={2}>
              <Grid
                container
                justifyContent={"space-between"}
                py={1}
                borderBottom={"solid"}
                borderColor={"#99b0f3"}
              >
                <Grid item>Wallet Balance</Grid>
                <Grid item fontWeight={"bold"}>
                  {userBalance.data
                    ? parseFloat(userBalance.data.formatted).toFixed(2)
                    : "-"}{" "}
                  {BASE_TOKEN}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={8} fontSize={20} p={2}>
              <Grid
                container
                justifyContent={"space-between"}
                py={1}
                borderBottom={"solid"}
                borderColor={"#99b0f3"}
              >
                <Grid item>Your Bots</Grid>
                <Grid item fontWeight={"bold"}>
                  {miners.data ? `${miners.data}` : "-"}{" 🤖"}
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={1} justifyContent={"center"}>
              <Grid item xs={8}>
                <FormControl variant="filled" fullWidth >
                  <InputLabel htmlFor="input-tokens" style={{fontFamily: "IBM Plex Mono, monospace", color: "#ffffff"}}>
                    Enter Amount
                  </InputLabel>
                  <FilledInput
                    id="input-tokens"
                    type="text"
                    value={userInput}
                    error={
                      userInput && userBalance.data.formatted && parseFloat(userInput) >  parseFloat(userBalance.data.formatted) 
                    }
                    autoFocus
                    onChange={(e) => {
                      setUserInput(handleDecimalsOnValue(e.target.value));
                    }}
                    style={{fontFamily: "IBM Plex Mono, monospace", color: "#ffffff" }}
                    endAdornment={
                      <InputAdornment position="end">
                        <span style={{ fontWeight: "bold" , color: "#ffffff"}}>{BASE_TOKEN}</span>
                      </InputAdornment>
                    }
                  ></FilledInput>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={4} justifyContent={"center"}>
              <Grid item xs={8}>
                <Button
                  style={{ fontWeight: "bold", fontFamily: "IBM Plex Mono, monospace", background: "linear-gradient(45deg , #00c6ff, #0072ff)" }}
                  variant="contained"
                  size="large"
                  fullWidth
                  
                  onClick={handleDeposit}
                >
                  BUY BOTS
                </Button>
              </Grid>
            </Grid>
            <Grid container mt={5} justifyContent={"center"}>
              <Grid item xs={8}>
                <Divider fullWidth />
              </Grid>
            </Grid>

            <Grid container py={4} justifyContent={"center"}>
              <Grid item xs={10}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  py={1}
                  fontSize={24}
                  fontWeight={"bold"}
                >
                  <Grid item>Your Rewards</Grid>  
                  <Grid item>

                    {eggsToMain.data ? truncate(formatEther(eggsToMain.data.toString() ), 3): <CircularProgress style={{color: "#84a4bc"}} size={20}/>}{" "}
                    {BASE_TOKEN}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              p={2}
              px={10}
              spacing={4}
              justifyContent={"space-between"}
            >
              <Grid item xs={12} md={6}>
                <Button
                     style={{ fontWeight: "bold", fontFamily: "IBM Plex Mono, monospace", backgroundColor: "#00C853" }}
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleCompound}
                >
                  COMPOUND
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                     style={{ fontWeight: "bold", fontFamily: "IBM Plex Mono, monospace", backgroundColor: "#00C853" }}
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleSell}
                >
                  CLAIM REWARDS
                </Button>
              </Grid>
            </Grid>
            <Grid container mt={5} justifyContent={"center"}>
              <Grid item xs={8}>
                <Divider fullWidth />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={4} justifyContent={"center"}>
              <Grid item xs={8} textAlign={"left"}>
                <Card variant="outlined" style={{backgroundColor: "rgba(0,0,0,0.25)", color: "#ffffff"}}>
                  <CardContent>
                    <Typography
                      sx={{ fontSize: 20, fontWeight: "bold", fontFamily: "IBM Plex Mono, monospace" }}
                      gutterBottom
                    >
                      Important Data
                    </Typography>
                    <Divider></Divider>
                    <Grid
                      py={1}
                      container
                      spacing={2}
                      justifyContent={"space-between"}
                      sx={{ fontSize: 16 }}
                    >
                      <Grid item>Daily Return </Grid>
                      <Grid item>8%</Grid>
                    </Grid>
                    <Grid
                      py={1}
                      container
                      spacing={2}
                      justifyContent={"space-between"}
                      sx={{ fontSize: 16 }}
                    >
                      <Grid item>APR</Grid>
                      <Grid item>
223,220%</Grid>
                    </Grid>
                    <Grid
                      pt={1}
                      container
                      spacing={2}
                      justifyContent={"space-between"}
                      sx={{ fontSize: 16 }}
                    >
                      <Grid item>Dev Fee</Grid>
                      <Grid item>5%</Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={6}>
            <Grid container justifyContent={"center"}>
              <Typography sx={{fontFamily: "IBM Plex Mono, monospace"}} fontSize={24}>Referral Link</Typography>
            </Grid>
            <Grid container justifyContent={"center"}>
              <Grid item xs={10}>
                {address ? (
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"center"}
                    fontSize={10}
                    mt={4}
                    lineHeight={2}
                    height={64}
                  >
                    <Paper sx={{backgroundColor: "rgba(0,0,0,0.25)", color: "#ffffff"}}>
                      <Box  p={1}>{`${APP_URL}?ref=${address}`}</Box>
                    </Paper>
                  </Grid>
                ) : (
                  <Skeleton height={64}></Skeleton>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} py={2}>
              <Grid item xs={6}>
                <CopyToClipboard
                  text={address ? `${APP_URL}?ref=${address}` : ""}
                >
                  <Button
                       style={{ fontWeight: "bold", fontFamily: "IBM Plex Mono, monospace",  background: "linear-gradient(45deg , #00c6ff, #0072ff)" }}
                    variant="contained"
                    size="large"
                    fullWidth
                    endIcon={<ContentPasteIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      toast.info("Copied Link to Clipboard");
                    }}
                  >
                    COPY TO CLIPBOARD
                  </Button>
                </CopyToClipboard>
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} py={2}>
              <Grid item fontSize={14} lineHeight={1.8} xs={8}>
                Earn 10% of the {BASE_TOKEN} from anyone who
                uses your referral link
              </Grid>
            </Grid>
            <Grid container justifyContent={"center"} py={5} spacing={5}>
              {/* <Grid item>
                <Link href="https://google.com/" target="_blank" rel="noopener">
                  <Twitter style={{color: "#03A9F4", fontSize: 42}} fontSize="large" />
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="https://t.me/BaseBotsOnBase" target="_blank" rel="noopener">
                  <Telegram style={{color: "#03A9F4", fontSize: 42}} fontSize="large" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ToastContainer theme="colored" position="bottom-left"/>
    </div>
  );
}

function mainApp() {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </WagmiProvider>
  );
}
export default mainApp;
